<template>
  <div>
    <router-view :key="$route.fullPath">
      <div id="Viewer_Div" @click="closeSel">
        <div id="Header-w">
          <Header />
        </div>

        <!--- 로그인이 된 상태에서만 보이기 --->
        <div class="headerW_Container" v-show="showMyPage">
          <div class="arrow"></div>
          <div class="myPageWrapper">
            <ul>
              <li class="name"><strong>{{ userName }}</strong> 님</li>
              <li>
                <a :href="this.$TARGETURL + 'mypage'">
                  마이페이지
                </a>
              </li>
              <li>
                <a :href="this.$TARGETURL + 'myhome'">
                  내가 만든 주택
                </a>
              </li>
              <li @click="logout">로그아웃</li>
            </ul>
          </div>
        </div>

        <div class="Pop_SideMenu">
          <div class="SideMenus_Btn">
            <div class="Prevs_Div" @click="navBackCommand">
              <img src="../assets/images/icons/arrow.png" class="prev" />
            </div>

            <div class="Nexts_Div">
              <img src="../assets/images/icons/arrow.png" class="next" />
            </div>
          </div>

          <div class="Pop_Tit">
            <div class="Pop_Wrapper">
              <h3 style="margin: 0px">{{ curType.title }}</h3>
              <!--- 로그인이 안 된 상태에서 편집하기 버튼 클릭하면 로그인 페이지로 이동 --->
              <a v-if="curType.title !== 'Proto.35D' && curType.title !== 'ADU'" class="editBtn"
                @click="onClickEditPopup">
                <img src="../assets//images//icons/edit.png" alt="">
                <p>편집하기</p>
              </a>
            </div>

            <div class="Pop_Spec">
              <p><img src="../assets/images/icons/size.png" />{{ curType.mmSize }}</p>
              <p><img src="../assets/images/icons/room.png" />{{ mRooms }}</p>
              <p><img src="../assets/images/icons/floor.png" />{{ mFloor }}</p>
              <p><img src="../assets/images/icons/bathroom.png" />{{ mBath }}</p>
            </div>
          </div>

          <div class="Floor_Thumb">
            <div class="Floor_Tit" @click="showTypeCommand">
              <p>전체보기</p>
            </div>
            <img :src="curType.thumbnail" @click="showTypeCommand" alt="" class="thumb" />

            <div class="Floor_Tit">
              <p>1F</p>
            </div>
            <img :src="curType.img1F" alt="" @click="openFPop(curType.img1F)" />

            <div class="Floor_Tit" v-if="is2f">
              <p>2F</p>
            </div>
            <img :src="curType.img2F" @click="openFPop(curType.img2F)" alt="" v-if="is2f" />
          </div>

          <!-- <button class="Make_Btn">
            <img src="../assets/images/icons/edit.png" alt="">
            직접 설계하기
          </button> -->

          <!-- <div class="FloorPlan_Div"></div> -->
        </div>

        <div class="Pop_Viewer">
          <div class="FPop_Div" v-show="showFP">
            <div class="Fpop_Menu"></div>
            <div class="Fpop_img">
              <img :src="selectFp" alt="" />
              <button @click="closeFP">X</button>
            </div>
          </div>

          <div class="Viewer_desc">
            <p>※ 다락옵션의 경우 지붕 형태에 따라 선택의 제약이 있을 수 있음.</p>
            <p>※ Option 모듈(현관모듈, 주차장모듈 등)은 외관 형상에 따라 선택의 제약이 있을 수 있음.</p>

            <p>※ 2층 이상의 주택은 관련법령에 따라 소방창이 필요하며, 건축물 배치에 따라 소방창 위치가 변경될 수 있음.</p>
          </div>

          <router-link to="/">
            <!-- <div class="closeBtn" @click="$router.go(-1)">
              <img src="../assets/images/OpenModule.png" alt="" /> 
            </div> -->
          </router-link>

          <div class="Layer_Div">
            <div class="Option_Btns">
              <input type="radio" checked="true" id="select" name="btNav" @click="openMd" />
              <label for="select">Modules</label>

              <input type="radio" id="select2" name="btNav" @click="openRF" />
              <label for="select2">Roofs</label>

              <input type="radio" v-if="isAdu" id="select3" name="btNav" @click="openADU" />
              <label for="select3" v-if="isAdu">ADU Example</label>
            </div>

            <div class="Module_List" v-if="showMd" style="align-items: center">
              <p @click="selectAnimationCommand" class="AnimateBtn">
                <span v-if="animButtonStatus('None')">Animate</span>
                <img v-if="animButtonStatus('Stop')" src="../assets/images/icons/play.png" alt="Play" />
                <img v-if="animButtonStatus('Run')" src="../assets/images/icons/pause.png" alt="Pause" />
              </p>

              <div class="MLThumb_Wrap">
                <div class="MLThumb" v-for="m in curModules" :key="m" @click="selectModuleCommand(m.name, $event)"
                  style="width: 250px; height: 98%; margin-right: 10px">
                  <div class="Module_Thumb" style="width: 250px; height: 98%">
                    <img :src="m.imgPath" alt="" />
                    <div style="
                        position: absolute;
                        color: beige;
                        text-align: center;
                        position: absolute;
                        color: beige;
                        text-align: center;
                        top: 5px;
                        left: 5px;
                        z-index: 50;
                        width: 60px;
                        height: 25px;
                        background: #d9d9d9;
                        color: #696d6f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 13px;
                        border-radius: 25px;
                      " v-if="m.isVisible == false" class="onoffBtn1">
                      Off
                    </div>
                    <div style="
                        position: absolute;
                        color: beige;
                        text-align: center;
                        top: 5px;
                        left: 5px;
                        z-index: 50;
                        width: 60px;
                        height: 25px;
                        background: gray;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 13px;
                        border-radius: 25px;
                      " v-if="m.isVisible == true" class="onoffBtn">
                      On
                    </div>
                    <div class="Txt_Div" style="">
                      <h3>{{ m.name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Module_List" v-if="showRF" style="align-items: center;">
              <p @click="selectAnimationCommand" class="AnimateBtn">
                <span v-if="animButtonStatus('None')">Animate</span>
                <img v-if="animButtonStatus('Stop')" src="../assets/images/icons/play.png" alt="Play" />
                <img v-if="animButtonStatus('Run')" src="../assets/images/icons/pause.png" alt="Pause" />
              </p>
              <div class="MLThumb_Wrap">
                <div v-for="(m, i) in curRoofs" :key="m" @click="selectRoofCommand(i)"
                  style="width: 250px; height: 98%; margin-right: 10px" class="MLThumb">
                  <div class="Module_Thumb" style="width: 100%; height: 100%">
                    <img :src="m.imgPath" alt="" />
                    <div
                      style=" position: absolute; color: beige; text-align: center; top: 5px; left: 5px; z-index: 50; width: 60px; height: 25px; background: #d9d9d9; color: #696d6f; display: flex; justify-content: center; align-items: center; font-size: 13px; border-radius: 25px;"
                      class="onoffHover1" v-if="m.isVisible == false">
                      Off
                    </div>

                    <div style="position: absolute; color: beige; text-align: center; top: 5px; left: 5px; z-index: 50; width: 60px; height: 25px; background: #696d6f; color: #d9d9d9; display: flex; justify-content: center;
                        align-items: center; font-size: 13px; border-radius: 25px;" class="onoffHover"
                      v-if="m.isVisible == true">
                      On
                    </div>
                    <div style="
                        position: absolute;
                        color: beige;
                        text-align: center;
                        top: 5px;
                        left: 75px;
                        z-index: 50;
                        width: 60px;
                        height: 25px;
                        background: #d9d9d9;
                        color: #696d6f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 13px;
                        border-radius: 25px;
                      " v-if="!m.isOptionVisible" class="onoffBtn1" @click="selectOptionCommand(i, m, $event)">
                      Option
                    </div>
                    <div style="position: absolute; color: beige; text-align: center; top: 5px; left: 75px; z-index: 50; width: 60px; height: 25px; background: #696d6f; color: #d9d9d9; display: flex; justify-content: center;
                        align-items: center; font-size: 13px; border-radius: 25px;" v-if="m.isOptionVisible"
                      class="onoffBtn1" @click="selectOptionCommand(i, m, $event)">
                      Option
                    </div>
                    <div class="Txt_Div" style="">
                      <h3>{{ m.name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Module_List" v-if="showADU" style="align-items: center; ">
              <p @click="selectAnimationCommand" class="AnimateBtn">
                <span v-if="animButtonStatus('None')">Animate</span>
                <img v-if="animButtonStatus('Stop')" src="../assets/images/icons/play.png" alt="Play" />
                <img v-if="animButtonStatus('Run')" src="../assets/images/icons/pause.png" alt="Pause" />
              </p>

              <div class="MLThumb_Wrap">
                <div v-for="(m, i) in curAdus" :key="m" @click="selectAduCommand(i)"
                  style="width: 250px; height: 98%; margin-right: 10px" class="MLThumb">
                  <div class="Module_Thumb" style="width: 100%; height: 100%">
                    <img :src="m.imgPath" alt="" />
                    <div
                      style="position: absolute; color: beige; text-align: center; top: 5px; left: 5px; z-index: 50; width: 60px; height: 25px; background: #d9d9d9; color: #696d6f; display: flex; justify-content: center; align-items: center; font-size: 13px; border-radius: 25px;"
                      class="onoffHover1" v-if="m.isVisible == false">
                      Off
                    </div>

                    <div style="position: absolute; color: beige; text-align: center; top: 5px; left: 5px; z-index: 50; width: 60px; height: 25px; background: #696d6f; color: #d9d9d9; display: flex; justify-content: center;
                        align-items: center; font-size: 13px; border-radius: 25px;" class="onoffHover"
                      v-if="m.isVisible == true">
                      On
                    </div>

                    <div class="Txt_Div" style="">
                      <h3>{{ m.name }}</h3>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <ThreeViewer :selectObj="resObj" v-on:animCallBack="animCallBack" />
      </div>

    </router-view>
    <LogIn @childEvent="LoginChild" @childEvent2="LoginChild2" v-if="showEditPopup" />
  </div>
</template>

<style scoped>
@import "../assets/css/ViewerPage.css";
@import "../assets/css/Responsive.css";


/* 3840 ~  / 가로형 */

@media (min-width: 3840px) {
  .Floor_Tit {
    height: 45px;
  }

  .Floor_Tit p {
    font-size: 1.2rem;
  }

  .Floor_Thumb .thumb {
    top: 0;
    width: 95%;
    height: 180%;
    object-fit: cover;
    border: 5px solid #d9d9d9;
    margin-bottom: 35px;
    cursor: pointer !important;
  }

  .Floor_Thumb img {
    top: 0;
    width: 95%;
    height: 180%;
    object-fit: contain;
    border: 5px solid #d9d9d9;
    background: #fff;
    cursor: pointer !important;
    margin-bottom: 35px;
  }
}

@media (max-width: 560px) {
  .Floor_Thumb {
    width: 100%;
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-template-rows: 5px 17px 70px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    cursor: pointer;
  }

  .Floor_Thumb .thumb {
    grid-row: 3;
    object-fit: cover;
    border: 3px solid #d9d9d9;
    width: 140px;
    height: 85px;
    margin-right: 15px;
    cursor: pointer !important;
  }

  .Floor_Thumb img {
    grid-row: 3;
    object-fit: contain;
    border: 3px solid #d9d9d9;
    width: 140px;
    height: 85px;
    margin-right: 15px;
    cursor: pointer !important;
  }

  .Floor_Tit {
    grid-row: 2;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Floor_Tit p {
    font-size: 7px;
    margin-top: 2px;
  }
}
</style>

<script>
import ThreeViewer from "../components/ThreeViewer.vue";
import axios from "axios";
import LogIn from "../components/LogIn.vue";
import Cookies from 'js-cookie';
import Header from './essential/headerTemplate.vue'

export default {
  metaInfo: {
    title: "Xigeist Home Configurator",
    titleTemplate: "ViewerPage",
    htmlAttrs: {
      lang: "ko",
      amp: true,
    },
  },

  components: {
    ThreeViewer,
    LogIn,
    Header
  },

  data() {
    return {
      showMdD: true,
      showMd: true,
      showRF: false,
      showADU: false,
      showEx: false,
      showFP: false,
      hide: true,
      selectFp: null,
      mFloor: "",
      mRooms: "",
      mBath: "",

      typeName: "",
      curType: {},
      curModules: [],
      curRoofs: [],
      curAdus: [],
      resObj: {},

      is2f: false,
      isAdu: false,
      animState: "None",
      showEditPopup: false,
      showMyPage: false,
      userIdx: "",
      userName: ""
    };
  },
  async created() {
    await this.getToken();
  },
  async beforeRouteEnter(to, from, next) {
    const typeName = to.params.typeName;
    const response = await axios.get("../../Types.json");
    const curType = response.data.find((x) => x.name == typeName);
    if (curType === undefined) {
      next("/Error");
    } else {
      next();
    }
  },
  mounted() {
    this.sliderInit();
    this.typeName = this.$route.params.typeName;
    this.initailType(this.typeName);
    document.title = `${this.typeName} | Home Self-Design System | XiGEIST`;
  },
  methods: {
    clickMyPage() {
      //this.showMyPage = !this.showMyPage;
      if (this.userName != '') {
        this.showMyPage = !this.showMyPage;
      } else {
        //window.location.href = this.$TARGETURL + "member/login";
        this.onClickEditPopup2();
      }
    },
    async getToken() {
      const cookie = Cookies.get('token');
      let data = {
        token: cookie == undefined ? '' : cookie
      }
      const res = await axios.post("/server/token_check", JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
      });
      if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

      }
      else if (res.data._data["length"] == 0) { // 토큰이 변경됐으면
        alert("다른 계정 로그인으로 인해 로그아웃됩니다");
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
        this.userIdx = '';
        this.userName = '';
        this.showMyPage = false; //마이페이지 닫음
        window.location.reload();
      }

      else if (res.status == 200) {
        this.userIdx = res.data._data[0]["idx"];
        this.userName = res.data._data[0]["NAME"];
        //this.showMyPage=true;
      }
    },
    logout() {
      alert("로그아웃 합니다");
      if (this.userCode == 1) {
        console.log("카카오로그아웃");
        this.kakaoLogout();
      }
      Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
      Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
      this.userIdx = '';
      this.userName = '';
      this.showMyPage = false;
      window.location.reload();
    },
    sliderInit() {
      const slider = document.querySelector(".MLThumb_Wrap");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });

      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });

      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });

      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
      });
    },
    closeSel(event) {
      var currentCli = document.getElementById("sellineName");
      if (currentCli) {
        if (!currentCli.contains(event.target)) {
          //    id sellineName     ，
          this.listLineUl = false;
        }
      }
    },
    async initailType(typeName) {
      const response = await axios.get("../../Types.json");
      this.curType = response.data.find((x) => x.name == typeName);
      this.ListInit();
      this.curModules = this.curType.modules;
      this.curRoofs = this.curType.roofs;
      if (this.curType.name == "ADU") {
        this.isAdu = true;
        this.curAdus.push(this.aduItemCreate(response, "Proto17S", "1F_Module2")); // test
        this.curAdus.push(this.aduItemCreate(response, "Proto25S", "1F_Module1"));
        this.curAdus.push(this.aduItemCreate(response, "Proto33S", "1F_Module4"));
        this.curAdus.push(this.aduItemCreate(response, "Proto43D", "2F_Module4"));
        this.curAdus.push(this.aduItemCreate(response, "Proto63D", "2F_Module1"));
      }
      this.resObj = { commandString: "InitType", curType: this.curType, aduTypes: this.curAdus };

      this.mFloor = `${this.curType.floor}-Story`;
      this.mRooms = `${this.curType.roomCnt}-Bed`;
      this.mBath = `${this.curType.bathRoomCnt}-Bath`;
      if (this.curType.floor == 2) {
        this.is2f = true;
      }
    },
    ListInit() {
      //objects 분배
      this.curType.objects.forEach((o) => {
        if (o.type === "Module" || o.type === "ModuleOption") {
          this.curType.modules.push(o);
        } else if (o.type === "Roof") {
          this.curType.roofs.push(o);
        } else if (o.type === "Exterior") {
          this.curType.exteriors.push(o);
        } else if (o.type === "Option") {
          this.curType.options.push(o);
        }
      });
    },
    showTypeCommand() {
      this.resObj = { commandString: "ShowType" };
    },
    selectModuleCommand(moduleName, e) {
      this.resObj = { commandString: "ShowModule", moduleName: moduleName };
    },
    selectRoofCommand(i) {
      this.resObj = { commandString: "ShowRoof", index: i + 1 };
    },
    selectOptionCommand(i, m, e) {
      e.stopPropagation();
      if (m.isVisible) {
        this.resObj = { commandString: "ShowOption", index: i + 1 };
      }
    },
    selectAduCommand(i) {
      this.resObj = { commandString: "ShowAdu", index: i + 1 };
    },
    selectAnimationCommand() {
      if (this.animState === "None") {
        this.animState = "Run";
      }
      else if (this.animState === "Run") {
        this.animState = "Stop";
      }
      else if (this.animState === "Stop") {
        this.animState = "Run";
      }

      let curMenu = "Roof";
      if (this.showMd) {
        curMenu = "Module";
      }
      else if (this.showADU) {
        curMenu = "ADU"
      }
      this.resObj = { commandString: "ShowAnimation", state: this.animState, curMenu: curMenu };
    },
    animCallBack() {
      this.animState = "None";
      this.resObj = { commandString: "ShowAnimation", state: this.animState, curMenu: "Roof" };
    },
    async navBackCommand() {
      if (this.global.typeHistory === undefined) {
        await this.$router.push({
          name: "PageSection",
          params: { typeName: "type2" },
        });
      } else {
        await this.$router.push({
          name: "PageSection",
          params: { typeName: this.global.typeHistory },
        });
      }
      this.global.moduleHistory = this.typeName;
    },
    showType: function () {
      this.openType = !this.openType;
    },
    openRF: function () {
      this.showRF = true;
      this.showMd = false;
      this.showADU = false;
    },
    openMd: function () {
      this.showMd = true;
      this.showMdD = false;
      this.showRF = false;
      this.showADU = false;
    },
    openADU: function () {
      this.showADU = true;
      this.showMd = false;
      this.showMdD = false;
      this.showRF = false;
    },
    openFPop: function (img) {
      this.showFP = true;
      this.selectFp = img;
    },
    closeFP: function () {
      this.showFP = false;
    },

    onClickRedirect: function () {
      window.open("https://www.xigeist.com", "_blank");
    },
    onClickRedirect2: function () {
      window.open("http://xigeist.bimpeers.com/", "_blank");
    },
    animButtonStatus(status) {
      if (status === this.animState) {
        return true;
      }
      return false;
    },
    aduItemCreate(response, itemName, aduName) {
      const item = JSON.parse(JSON.stringify(response.data.find((x) => x.name == itemName)));
      const aduModel = {};
      aduModel.name = itemName;
      aduModel.imgPath = `../models/ADU/example/${itemName}/img/RF_Roof.png`;
      aduModel.isVisible = false;
      aduModel.aduName = aduName;
      aduModel.objects = [];

      item.objects.forEach(element => {
        if (element.name == aduName) {
          element.name += "ADU";
          aduModel.objects.push(element);
        }
        else if (element.type == "Module" || element.type == "Base") {
          aduModel.objects.push(element);
        }
        else if (element.type == "Option") {
          const str = element.name.split("_")[0];
          if (str.indexOf(1) > -1) {
            aduModel.objects.push(element);
          }
        }
        else if (element.type == "Exterior" || element.type == "Roof") {
          const str = element.name.split("_")[1];
          if (str.indexOf(1) > -1) {
            aduModel.objects.push(element);
          }
        }
      });
      return aduModel;
    },
    onClickEditPopup() { // 편집하기 팝업 on
      // if (this.userName != '') { // 로그인 돼있으면 이동
      //   console.log(this.$TARGETURL);
      //   window.location.href = this.$TARGETURL + this.typeName;
      // }
      // else { // 로그인 안 돼있으면 로그인하라는 팝업
      //   console.log(this.showEditPopup);
      //   this.showEditPopup = true;
      //   this.goEditPage = true;
      // }
      window.location.href = this.$TARGETURL + this.typeName;
    },
    onClickEditPopup2() {
      this.showEditPopup = true;
    },
    closeEditPop() { //편집하기 팝업 off
      this.showPopup = false;
    },
    LoginChild() {
      this.getToken();
      this.showEditPopup = false;
      console.log(this.showEditPopup);
      if (this.goEditPage) {
        this.goEditPage = false;
        window.location.href = this.$TARGETURL + this.typeName;
      }
    },
    LoginChild2() {
      this.showEditPopup = false;
    },
    kakaoLogout() {
      window.Kakao.API.request({
        url: '/v1/user/unlink',
        success: function (response) {
          console.log(response);
          document.cookie = "token=" + "";
          //alert("로그아웃 되었어용");
        },
        fail: function (error) {
          console.log(error);
        },
      });
    },

  },

};
</script>
