import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export default class ThreeView{

    constructor(modules)
    {
        this.modules = modules
        this.moduleMap = new Map()
        this.aduMap = new Map()
        this.isLoaded = false;
        this.selectType = null;
        this.curType = null;
        this.aduTypes = null;
        this.curAdu = null;
        this.curIndex = 1;
    }

    Init()
    {
        this.InitailSetting();
        this.InitailLoad();
    }

    InitailSetting()
    {
        this.threeCanvas = document.getElementById('ThreeCanvas');
        this.scene = new THREE.Scene();
        const width = this.threeCanvas.offsetWidth;
        const height = this.threeCanvas.offsetHeight;
        //this.camera = new THREE.OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, 100, 1000000 );
        this.camera = new THREE.PerspectiveCamera(75, this.threeCanvas.offsetWidth / this.threeCanvas.offsetHeight, 100, 100000)
        //this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.maxDistance = 25000;
        this.controls.minDistance = 5000;
        this.controls.minPolarAngle = 0;
        this.controls.maxPolarAngle = Math.PI/2.2;
        this.loadManager = new THREE.LoadingManager();
        this.gltfLoader = new GLTFLoader(this.loadManager);
        this.rgbeLoader = new RGBELoader(this.loadManager);

        this.renderer.setSize( this.threeCanvas.offsetWidth, this.threeCanvas.offsetHeight);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.autoUpdate = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.setClearColor(0x000000, 0.0);
        // const plane = new THREE.Plane( new THREE.Vector3( 0, - 1, 0 ), 2000 );
        // this.renderer.clippingPlanes.push(plane);      
        // this.renderer.localClippingEnabled = true;

        this.controls.enableDamping = true
        this.controls.target.set(0, 1, 0)

        this.intensity = 1;
        this.lightColor = 0xFFFFFF;
        this.bias = -0.001;

        this.directionalLight = new THREE.DirectionalLight(this.lightColor, this.intensity);
        this.directionalLight.position.set(10000,20000,10000)
        this.directionalLight.castShadow = true
        this.directionalLight.shadow.mapSize.width = 6144;
        this.directionalLight.shadow.mapSize.height = 6144;
        const d = 80000;
        this.directionalLight.shadow.camera.left = - d;
        this.directionalLight.shadow.camera.right = d;
        this.directionalLight.shadow.camera.top = d;
        this.directionalLight.shadow.camera.bottom = - d;
        this.directionalLight.shadow.camera.near = 75;
        this.directionalLight.shadow.camera.far = 100000;
        this.directionalLight.shadow.antialias = true;
        this.directionalLight.shadow.bias = this.bias;

        this.rightLight = new THREE.DirectionalLight( this.lightColor );
        this.rightLight.position.set(40000,40000,0);
        this.rightLight.intensity = this.intensity;

        this.leftLight = new THREE.DirectionalLight( this.lightColor );
        this.leftLight.position.set(-40000,40000,0);
        this.leftLight.intensity = this.intensity;

        this.backLight = new THREE.DirectionalLight( this.lightColor );
        this.backLight.position.set(0,40000,-40000);
        this.backLight.intensity = 1;
        this.backLight.castShadow = false;

        this.upLight = new THREE.DirectionalLight( this.lightColor );
        this.upLight.position.set(0,-40000,-40000);
        this.upLight.intensity = 1;
        this.upLight.castShadow = false;
        // this.helper = new THREE.DirectionalLightHelper( this.directionalLight, 5 );

        // this.directionalLight.position.set(0,10000,-10000);
        this.helper = new THREE.DirectionalLightHelper( this.directionalLight, 5 );
        

        this.threeCanvas.appendChild(this.renderer.domElement);
    }

    // CreateGround(width,height,x,y)
    // {
    //     this.ground = new THREE.Mesh(new THREE.PlaneGeometry(width, height, 32), new THREE.MeshStandardMaterial({ color: 0x131313}));
    //     this.ground.rotation.x = - Math.PI / 2
    //     this.ground.receiveShadow = true
    //     this.ground.material.shininess = 0
    //     this.ground.position.set(x,0,y);
    //     this.scene.add(this.ground);
    // }

    InitailLoad()
    {
        const progressBarContainer = document.querySelector('.progress-bar-container');
        const progressBar = document.getElementById('progress-bar');
        
        this.loadManager.onStart = async function()
        {
            progressBarContainer.style.display = 'flex';
        }
        this.loadManager.onProgress = function(url,loaded,total){
            progressBar.value = (loaded/total) * 100;
        }
        this.loadManager.onLoad = function(){
            progressBarContainer.style.display = 'none';
        }

        //Scene Load
        this.rgbeLoader.setPath( '../../textures/' ).loadAsync('limpopo_golf_course_1k.hdr')
        .then(texture=>{
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.environment = texture;
            //this.scene.background = texture;
        });
    }

    SceneClean(isCameraClean = true)
    {
        this.scene.clear();
        this.scene.add(this.rightLight);
        this.scene.add(this.leftLight);
        this.scene.add(this.backLight);
        this.scene.add(this.directionalLight);
        this.scene.add(this.upLight);
        //this.scene.add( this.helper );
        //this.scene.add(this.ground);
        
        if(isCameraClean)
        {
            this.controls.target.set(0, 1, 0)
            this.camera.castShadow = true;
            this.camera.position.set(0, 18000, 45000);
            this.camera.lookAt(new THREE.Vector3(0,0,0));
        }
    }

    SceneUpdate()
    {
        this.curType.objects.forEach(o=>{
            if(o.isVisible === true)
            {
                this.scene.add(this.moduleMap.get(o.name));
            }
        });
    }

    async LoadObjectAsync(typeName,obj,map)
    {
        this.gltfLoader.loadAsync(`../../models/${typeName}/gltf/${obj.name}.gltf`).then(object=>{
            const instance = object.scene.clone()
            instance.receiveShadow = true;
            instance.castShadow = true;
            instance.position.x = 0;
            instance.position.y = 0;
            instance.position.z = 0;

            instance.traverse(function (child) {
                if (child.isMesh) {
                  child.castShadow = true;
                  child.receiveShadow = true;
                  const material = child.material;
                  material.onBeforeCompile = function( shader ) {
                    shader.fragmentShader = shader.fragmentShader.replace(
                        '#include <output_fragment>',
                        `vec3 backfaceColor = vec3( 0.2, 0.2, 0.2);
                        gl_FragColor = ( gl_FrontFacing ) ? vec4( outgoingLight, diffuseColor.a ) : vec4( backfaceColor, opacity );`
                    )
                };
                material.side = THREE.DoubleSide;
                }
             });
             obj.isVisible = false;
             map.set(obj.name,instance)
        });
    }

    AddScene(modelue)
    {
        this.scene.add(this.moduleMap.get(modelue.name));
        modelue.isVisible = true;
    }

    ShowType(index)
    {
        this.curIndex = index;
        this.SceneClean(false);console.log(this.curType,this.moduleMap)
        const target = this.curType.roofs.find(x=>x.name.split("_")[1].indexOf(index) > -1);
        const base = this.curType.objects.find(o=>o.type === "Base")
        if(base != undefined)
        {
            this.AddScene(base);
        }
        
        if(target.isVisible == false)
        {
            this.curType.modules.forEach(o=>{
                if(o.type === "ModuleOption")
                {
                    o.isVisible = false;
                }
                else
                {
                    this.AddScene(o);
                }
            });
        }
        else
        {
            if(base != undefined)
            {
                base.isVisible = false;
            }
        }
        
        let ret = false;
        this.curType.roofs.forEach(o=>{
            const str = o.name.split("_")[1];
            if(str.indexOf(index) > -1)
            {
                o.isVisible = !o.isVisible;
                o.isOptionVisible = o.isVisible;
                ret = o.isOptionVisible;
            }
            else{
                o.isVisible = false;
                o.isOptionVisible = false;
            }
        });
        this.curType.exteriors.forEach(o=>{
            const str = o.name.split("_")[1];
            if(str.indexOf(index) > -1)
            {
                o.isVisible = !o.isVisible;
            }
            else{
                o.isVisible = false;
            }
        });
        this.curType.options.forEach(o=>{
            const str = o.name.split("_")[0];
            
            if(str.indexOf(index) > -1)
            {
                o.isVisible = ret;
            }
            else{
                o.isVisible = false;
            }
        });
        this.SceneUpdate();
    }

    ShowOption(index)
    {
        if(!this.curType?.options)
            return;

        this.SceneClean(false);

        let ret = false;
        const zero = this.curType.options.find(x=>x.name == 'Option0');
        this.curType.options.forEach(o=>{
            const str = o.name.split("_")[0];
            
            if(str.indexOf(index) > -1)
            {
                o.isVisible = !o.isVisible;
                ret = o.isVisible;
                zero.isVisible = !o.isVisible;
            }
            else{
                o.isVisible = false;
            }
        });

        this.curType.roofs.forEach(o=>{
            const str = o.name.split("_")[1];
            if(str.indexOf(index) > -1)
            {
                o.isOptionVisible = ret;
            }
            else{
                o.isOptionVisible = false;
            }
        });
        console.log(this.curType)
        this.SceneUpdate();
    }

    ShowAdu(index)
    {
        const aduType = this.aduTypes[index-1];
        this.curAdu = aduType;
        if(aduType.isVisible)
        {
            aduType.isVisible = false;
            this.SceneClean();
        }
        else
        {
            this.aduTypes.forEach((type)=>{type.isVisible = false});
            aduType.isVisible = true;
            this.SceneClean();
            aduType.objects.forEach(element => {
                this.scene.add(this.aduMap.get(aduType.name+element.name));
            });
        }
    }

    LoadType(curType)
    {
        this.curType = curType;
        //this.CreateGround(this.curType.groundWidth,this.curType.groundHeight,this.curType.groundOffsetX,this.curType.groundOffsetY);
        this.SceneClean();
        curType.objects.forEach(o=>{this.LoadObjectAsync(curType.name,o,this.moduleMap);});
    }

    LoadAduAsync(aduType)
    {
        this.gltfLoader.loadAsync(`../../models/ADU/example/${aduType.name}/gltf/${aduType.aduName}ADU.gltf`).then(object=>{
            const instance = object.scene.clone()
            instance.receiveShadow = true;
            instance.castShadow = true;
            instance.position.x = 0;
            instance.position.y = 0;
            instance.position.z = 0;

            instance.traverse(function (child) {
                if (child.isMesh) {
                  child.castShadow = true;
                  child.receiveShadow = true;
                  const material = child.material;
                  material.onBeforeCompile = function( shader ) {
                    shader.fragmentShader = shader.fragmentShader.replace(
                        '#include <output_fragment>',
                        `vec3 backfaceColor = vec3( 0.2, 0.2, 0.2);
                        gl_FragColor = ( gl_FrontFacing ) ? vec4( outgoingLight, diffuseColor.a ) : vec4( backfaceColor, opacity );`
                    )
                };
                material.side = THREE.DoubleSide;
                }
             });
             this.aduMap.set(`${aduType.name}${aduType.aduName}ADU`,instance)
        });
    }

    async LoadADUObjectAsync(typeName,obj)
    {
        this.gltfLoader.loadAsync(`../../models/${typeName}/gltf/${obj.name}.gltf`).then(object=>{
            const instance = object.scene.clone()
            instance.receiveShadow = true;
            instance.castShadow = true;
            instance.position.x = 0;
            instance.position.y = 0;
            instance.position.z = 0;

            instance.traverse(function (child) {
                if (child.isMesh) {
                  child.castShadow = true;
                  child.receiveShadow = true;
                  const material = child.material;
                  material.onBeforeCompile = function( shader ) {
                    shader.fragmentShader = shader.fragmentShader.replace(
                        '#include <output_fragment>',
                        `vec3 backfaceColor = vec3( 0.2, 0.2, 0.2);
                        gl_FragColor = ( gl_FrontFacing ) ? vec4( outgoingLight, diffuseColor.a ) : vec4( backfaceColor, opacity );`
                    )
                };
                material.side = THREE.DoubleSide;
                }
             });
             obj.isVisible = false;
             this.aduMap.set(typeName+obj.name,instance)
        });
    }

    LoadAduType(aduTypes)
    {
        this.aduTypes = aduTypes;
        aduTypes.forEach((aduType)=>{
            aduType.objects.forEach(o=>{
                if(o.name,o.name.indexOf("ADU") === -1)
                {
                    this.LoadADUObjectAsync(aduType.name,o);
                }
                else
                {
                    this.LoadAduAsync(aduType);
                }
            });
        });
        
    }

    ModuleAnimationInit(modelName)
    {
        if(this.TypeMap.has(modelName))
        {
            this.TypeMap.get(modelName).forEach(floor => {
                floor.forEach(module =>{
                    module.ClearModel();
                });
            });
        }
    }

    SetAllVisible(value)
    {
        this.curType.objects.forEach(o=>{o.isVisible = value;});
    }
}