//import { createApp } from 'vue'
//import App from './App.vue'

//createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import global from './script/global'
import LogIn from './components/LogIn.vue';
import { reactive } from 'vue'



const app = createApp(App)
app.use(router).mount('#app')
app.component('LogIn', LogIn);

//자이 서버 용
  app.config.globalProperties.$TARGETURL = "https://self-design.xigeist.com/"; //2차
  app.config.globalProperties.$TARGETURL2 = "https://selfdesign.xigeist.com/"; //1차
  app.config.globalProperties.$AUTHURL = "https://xiapi.xigeist.com/" //본인인증서버
  app.config.globalProperties.$DOMAIN = ".xigeist.com" //쿠키 유지 최상위 도메인
//app.config.globalProperties.$SERVERURL = "https://xiapi.xigeist.com/" //서버
//app.config.globalProperties.$SERVERURL = "http://localhost/" //서버
//  app.config.globalProperties.$SERVERURL = "http://10.130.2.41/" //서버


//빔피어스 서버 테스트 용

  //app.config.globalProperties.$TARGETURL = "http://xiviewer.bimpeers.com/"; //2차
  //app.config.globalProperties.$TARGETURL2 = "http://xiclient.bimpeers.com/"; //1차
//  app.config.globalProperties.$SERVERURL = "http://xiapiserver.bimpeers.com:4486/" //서버
  //app.config.globalProperties.$AUTHURL = "http://test-service.bimpeers.com:4487/" //본인인증서버
   //app.config.globalProperties.$DOMAIN = ".bimpeers.com" //쿠키 유지 최상위 도메인

//로컬 테스트 용
  //app.config.globalProperties.$TARGETURL = "http://localhost:8080/"; // 2차 뷰어
  //app.config.globalProperties.$TARGETURL2 = "http://localhost:8081/"; // 1차 클라이언트
// //app.config.globalProperties.$SERVERURL = "http://xiapiserver.bimpeers.com:4486/" //서버
 //app.config.globalProperties.$SERVERURL = "http://localhost:12008/"
// app.config.globalProperties.$AUTHURL = "http://localhost:55239/"


//app.config.globalProperties.$TARGETURL = "http://test-service.bimpeers.com:5006/"; // 2차 뷰어
//app.config.globalProperties.$TARGETURL = "http://1.214.117.116:5006/";
//app.config.globalProperties.$TARGETURL2 = "http://test-service.bimpeers.com/"; // 1차 클라이언트
//app.config.globalProperties.$TARGETURL = "http://1.214.117.116:5006/";
//app.config.globalProperties.$TARGETURL2 = "http://1.214.117.116/";


// reactive stateddddgcxxx
const state = reactive({
    count: 0
  })

app.config.globalProperties.global = global;