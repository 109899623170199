<template>
  <div id="S2_Div">
    <div id="Header-w">
      <div class="hw-tit">
        <Header />
      </div>
    </div>

    <!--- 로그인이 된 상태에서만 보이기 --->
    <div class="headerW_Container" v-show="showMyPage">
      <div class="arrow"></div>
      <div class="myPageWrapper">
        <ul>
          <li class="name"><strong>{{ userName }}</strong> 님</li>
          <li>
            <a :href="this.$TARGETURL + 'mypage'">
              마이페이지
            </a>
          </li>
          <li>
            <a :href="this.$TARGETURL + 'myhome'">
              내가 만든 주택
            </a>
          </li>
          <li @click="logout">로그아웃</li>
        </ul>
      </div>
    </div>
    <!-- 방, 화장실 개수 Section START -->

    <div class="S2_SideMenu">
      <div class="SideMenu_Btn">
        <router-link to="/" class="Prev_Div">
          <img src="../assets/images/icons/arrow.png" class="prev" />
        </router-link>

        <div class="Next_Div" @click="nextNav">
          <img src="../assets/images/icons/arrow.png" class="next" />
        </div>
      </div>

      <div class="SideMenu_Tit">
        <img src="../assets/images/icons/question.png" alt="" />
        <h3 v-if="typeName == 'type1'">방이 몇 개인 집을 원하시나요?</h3>
        <h3 v-if="typeName == 'type2'">몇 평형대의 집을 원하시나요?</h3>
        <div class="SideMenu_desc">
          <p>{{ Types.length }}개의 모델이 등록되어 있습니다.</p>
        </div>
      </div>

      <div class="Spec_Div">
        <div class="Room_Div" v-if="typeName == 'type1'">
          <p><img src="../assets/images/icons/room.png" alt="" />방 개수</p>
          <div class="Room_Btns">
            <div>
              <input type="checkbox" id="select" @click="onClickRoomFilter($event, 1, 5, RoomFilters, 'rm')" /><label
                for="select">1</label>
            </div>
            <div>
              <input type="checkbox" id="select2" @click="onClickRoomFilter($event, 2, 5, RoomFilters, 'rm')" /><label
                for="select2">2</label>
            </div>
            <div>
              <input type="checkbox" id="select3" @click="onClickRoomFilter($event, 3, 5, RoomFilters, 'rm')" /><label
                for="select3">3</label>
            </div>
            <div>
              <input type="checkbox" id="select4" @click="onClickRoomFilter($event, 4, 5, RoomFilters, 'rm')" /><label
                for="select4">4</label>
            </div>
            <div>
              <input type="checkbox" id="select5" @click="onClickRoomFilter($event, 5, 5, RoomFilters, 'rm')" /><label
                for="select5">5+</label>
            </div>
          </div>
        </div>
        <div class="BathRoom_Div" v-if="typeName == 'type1'">
          <p><img src="../assets/images/icons/bathroom.png" alt="" />화장실 개수</p>
          <div class="BathRoom_Btns">
            <div>
              <input type="checkbox" id="select6"
                @click="onClickRoomFilter($event, 1, 3, BathRoomFilters, 'brm')" /><label for="select6">1</label>
            </div>
            <div>
              <input type="checkbox" id="select7"
                @click="onClickRoomFilter($event, 2, 3, BathRoomFilters, 'brm')" /><label for="select7">2</label>
            </div>
            <div>
              <input type="checkbox" id="select8" @click="onClickRoomFilter($event, 3, 3, BathRoomFilters, 'brm')" />
              <label for="select8">3</label>
            </div>
          </div>
        </div>

        <div class="Size_Div" v-if="typeName == 'type2'">
          <p><img src="../assets/images/icons/size.png" alt="" />평형</p>
          <div class="Size_Btns">
            <div>
              <input type="checkbox" id="select9" @click="onClickAreaFilter($event, 29, 1)" /><label
                for="select9">20py↓</label>
            </div>
            <div>
              <input type="checkbox" id="select10" @click="onClickAreaFilter($event, 30, 0)" /><label
                for="select10">30py</label>
            </div>
            <div>
              <input type="checkbox" id="select11" @click="onClickAreaFilter($event, 40, 0)" /><label
                for="select11">40py</label>
            </div>

            <div>
              <input type="checkbox" id="select12" @click="onClickAreaFilter($event, 51, 2)" /><label
                for="select12">50py↑</label>
            </div>
          </div>
        </div>

        <div class="Floor_Div" v-if="typeName == 'type2'">
          <p><img src="../assets/images/icons/floor.png" alt="" />층수</p>
          <div class="Floor_Btns">
            <input type="checkbox" id="select13" @click="onClickFloorFilter($event, 1)" /><label
              for="select13">1</label>
            <input type="checkbox" id="select14" @click="onClickFloorFilter($event, 2)" /><label
              for="select14">2</label>
          </div>
        </div>
      </div>
    </div>


    <div class="S2_List">
      <div class="S2_Thumbs_Wrap prevent">
        <div class="S2_Thumbs" v-for="t in DisplayTypes" :key="t">
          <router-link :to="t.viewPath">
            <div class="S2_Thumb">
              <div class="Thumb_Tit">
                <p>{{ t.title }}</p>
              </div>
              <img :src="t.thumbnail" alt="" />
              <div class="Type_desc">
                <p>
                  {{ t.mmSize }} ({{ t.areaSize }} py) | {{ t.floor }}-Story |
                  {{ t.roomCnt }}-Bed | {{ t.bathRoomCnt }}-Bath
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- <div class="pagination_Div">
        <div class="pagination">
          <span class="btn_f" @click="pageMove(pages[0])"></span>
          <span class="btn_p" @click="pageMove(curPage-1)"></span>
          <span class="pageN" v-for="page in pages" :key="page"
                @click="pageMove(page)" 
                v-bind:style="{color : getPageColor(page)}">
                {{
                  page
                }}</span>
          <span class="btn_n" @click="pageMove(curPage+1)"> </span>
          <span class="btn_l" @click="pageMove(pages.at(-1))"></span>
        </div>
      </div> -->
    </div>
    <!-- 방, 화장실 개수 Section END -->
    <LogIn @childEvent="getToken" @childEvent2="LoginChild2" v-if="showEditPopup" />
  </div>
</template>

<style scoped>
@import "../assets/css/PageSection2.css";
@import "../assets/css/Responsive.css";
</style>

<script>
import axios from "axios";
import { RedFormat } from "three";
import LogIn from "../components/LogIn.vue";
import Cookies from 'js-cookie';
import Header from "./essential/headerTemplate.vue"

export default {
  name: "component.vue",

  components: {
    LogIn,
    Header
  },

  data() {
    return {
      totalType: 0,
      typeName: "",
      Types: [],
      DisplayTypes: [],
      RoomFilters: new Map(),
      BathRoomFilters: new Map(),
      FloorFilterMap: new Map(),
      AreaFilterMap: new Map(),
      pages: [], //페이지 배열,
      curPage: 1,
      pageActiveColor: '#006B8E',
      pageNomalColor: '#696d6f',
      showMyPage: false,
      userName: '',
      userIdx: '',
      showEditPopup: false
    };
  },
  async beforeRouteEnter(to, from, next) {
    const typeName = to.params.typeName;
    if (typeName === "type1" || typeName === "type2") {
      next();
    }
    else {
      next("/Error");
    }
  },
  async created() {
    await this.getToken();
  },
  async mounted() {
    this.typeName = this.$route.params.typeName;
    this.global.typeHistory = this.typeName;
    const response = await axios.get("../../Types.json");
    this.Types = response.data;
    this.DisplayTypes = [...this.Types];
    this.totalType = this.Types.length; //totalType 가져오기
    document.title = 'LIST | Home Self-Design System | XiGEIST';
  },
  methods: {
    clickMyPage() {
      //this.showMyPage = !this.showMyPage
      if (this.userName != '') {
        this.showMyPage = !this.showMyPage;
      } else {
        //window.location.href = this.$TARGETURL + "member/login";
        this.onClickEditPopup();
      }
    },
    async getToken() {
      const cookie = Cookies.get('token');
      let data = {
        token: cookie == undefined ? '' : cookie
      }
      const res = await axios.post("/server/token_check", JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
      });
      if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

      }
      else if (res.data._data["length"] == 0) { // 토큰이 변경됐으면
        alert("다른 계정 로그인으로 인해 로그아웃됩니다.");
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
        this.userIdx = '';
        this.userName = '';
        this.showMyPage = false; //마이페이지 닫음
        window.location.reload();
      }
      else if (res.status == 200) {
        this.userIdx = res.data._data[0]["idx"];
        this.userName = res.data._data[0]["NAME"];
        console.log(this.userName);
        //this.showMyPage=true;
      }
    },
    logout() {
      alert("로그아웃 합니다");
      if (this.userCode == 1) {
        console.log("카카오로그아웃");
        this.kakaoLogout();
      }
      Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
      Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
      this.userIdx = '';
      this.userName = '';
      this.showMyPage = false;
      window.location.reload();
    },
    getPageColor(page) {
      if (this.curPage === page) {
        return this.pageActiveColor
      }
      return this.pageNomalColor
    },
    onClickRedirect: function () {
      window.open("https://www.xigeist.com", "_blank");
    },
    onClickRoomFilter(e, i, maxRoom, map, keyStr) {
      const checked = e.target.checked;
      const key = `${i}`;

      const filter = (type) => {
        if (keyStr == "rm") {
          if (i === maxRoom) {
            return type.filter((o) => o.roomCnt >= i);
          }
          return type.filter((o) => o.roomCnt == i);
        } else {
          if (i === maxRoom) {
            return type.filter((o) => o.bathRoomCnt >= i);
          }
          return type.filter((o) => o.bathRoomCnt == i);
        }
      };

      if (checked) {
        if (!map.has(key)) {
          map.set(key, filter);
        }
      } else {
        if (map.has(key)) {
          map.delete(key);
        }
      }

      this.SetDisplayType1List();
    },
    onClickFloorFilter(e, i) {
      const checked = e.target.checked;
      const key = `fl${i}`;
      const filter = (type) => {
        return type.filter((o) => o.floor == i);
      };
      if (checked) {
        if (!this.FloorFilterMap.has(key)) {
          this.FloorFilterMap.set(key, filter);
        }
      } else {
        if (this.FloorFilterMap.has(key)) {
          this.FloorFilterMap.delete(key);
        }
      }
      this.SetDisplayType2List();
    },
    onClickAreaFilter(e, i, option) {
      const checked = e.target.checked;
      const key = `fl${i}`;
      const filter = (type) => {
        if (option === 1) {
          return type.filter((o) => o.areaSize <= i);
        } else if (option === 2) {
          return type.filter((o) => o.areaSize >= i);
        } else {
          return type.filter((o) => o.areaSize >= i && o.areaSize < i + 10);
        }
      };
      if (checked) {
        if (!this.AreaFilterMap.has(key)) {
          this.AreaFilterMap.set(key, filter);
        }
      } else {
        if (this.AreaFilterMap.has(key)) {
          this.AreaFilterMap.delete(key);
        }
      }
      this.SetDisplayType2List();
    },
    DisplaySort(a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      if (a.name === b.name) return 0;
    },
    SetDisplayType1List() {
      this.DisplayTypes.length = 0;
      if (this.RoomFilters.size == 0 && this.BathRoomFilters.size == 0) {
        this.DisplayTypes = [...this.Types];
        this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        return;
      } else if (this.RoomFilters.size == 0 && this.BathRoomFilters.size > 0) {
        this.BathRoomFilters.forEach((f) => {
          Array.prototype.push.apply(this.DisplayTypes, f(this.Types));
        });
        this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        return;
      } else if (this.BathRoomFilters.size == 0 && this.RoomFilters.size > 0) {
        this.RoomFilters.forEach((f) => {
          Array.prototype.push.apply(this.DisplayTypes, f(this.Types));
        });
        this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        return;
      } else {
        const rmArr = [];
        const brmArr = [];
        this.RoomFilters.forEach((f) => {
          Array.prototype.push.apply(rmArr, f(this.Types));
        });
        this.BathRoomFilters.forEach((f) => {
          Array.prototype.push.apply(brmArr, f(this.Types));
        });
        const ret = rmArr.filter((x) => brmArr.findIndex((o) => o.name === x.name) > -1);
        this.DisplayTypes = [...ret];
        this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        return;
      }

      // this.DisplayTypes = this.DisplayTypes.filter((element, index) => {
      //   const target = this.DisplayTypes.find((x) => x.name === element.name);
      //   return this.DisplayTypes.indexOf(target) === index;
      // });
    },
    SetDisplayType2List() {
      this.DisplayTypes.length = 0;
      if (this.FloorFilterMap.size == 0 && this.AreaFilterMap.size == 0) {
        this.DisplayTypes = [...this.Types];
        return;
      } else if (this.FloorFilterMap.size == 0 && this.AreaFilterMap.size > 0) {
        this.AreaFilterMap.forEach((f) => {
          Array.prototype.push.apply(this.DisplayTypes, f(this.Types));
          this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        });
        return;
      } else if (this.AreaFilterMap.size == 0 && this.FloorFilterMap.size > 0) {
        this.FloorFilterMap.forEach((f) => {
          Array.prototype.push.apply(this.DisplayTypes, f(this.Types));
          this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        });
        return;
      } else {
        const flArr = [];
        const areaArr = [];
        this.FloorFilterMap.forEach((f) => {
          Array.prototype.push.apply(flArr, f(this.Types));
        });
        this.AreaFilterMap.forEach((f) => {
          Array.prototype.push.apply(areaArr, f(this.Types));
        });
        const ret = flArr.filter((x) => areaArr.findIndex((o) => o.name === x.name) > -1);
        this.DisplayTypes = [...ret];
        this.DisplayTypes = this.DisplayTypes.sort(this.DisplaySort);
        return;
      }
    },
    async nextNav() {
      if (this.global.moduleHistory === undefined) {
        await this.$router.replace({
          name: "ViewerPage",
          params: { typeName: "Volume20" },
        });
      }
      else {
        await this.$router.replace({
          name: "ViewerPage",
          params: { typeName: this.global.moduleHistory },
        });
      }
    },
    onClickEditPopup() { // 편집하기 팝업 on
      // 로그인 안 돼있으면 로그인하라는 팝업
      this.showEditPopup = true;

    },
    LoginChild2() {
      this.showEditPopup = false;
    },
    kakaoLogout() {
      window.Kakao.API.request({
        url: '/v1/user/unlink',
        success: function (response) {
          console.log(response);
          document.cookie = "token=" + "";
          //alert("로그아웃 되었어용");
        },
        fail: function (error) {
          console.log(error);
        },
      });
    },


  },
};
</script>
