import { createWebHistory, createRouter } from "vue-router";
import MainPage from "../views/MainPage.vue";
import PageSection2 from "../components/PageSection2.vue";
import ViewerPage from "../components/ViewerPage.vue";
import ErrorPage from "../components/ErrorPage.vue";
import LoginNCallback from "../components/LoginNCallback.vue";



const routes = [
    {
        path: "/",
        name: "MainPage",
        component: MainPage,
        
    },
    {
        path: "/section/:typeName",
        name: "PageSection",
        component: PageSection2,
    },
    {
        path: "/viewer/:typeName",
        name: "ViewerPage",
        component:ViewerPage,
    },

    {
        path: '/:pathMatch(.*)*',
        name: "ErrorPage",
        component:ErrorPage,
    },
    {
        path: "/callback",
        name: "LoginNCallback",
        component: LoginNCallback,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;


