<template>
  <div>
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <router-view />
  </div>
</template>

<style>
@font-face {
  font-family: "Pretendard";
  src: url("../src/assets/fonts/Pretendard-Regular.ttf");
  font-weight: 400;
}

body {
  margin: 0px;
}
</style>
