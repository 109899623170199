<template>
  <div id="Home_Main">
    <div id="Header" class="grid" v-if="active">
      <div class="h-title">
        <!-- <img src="../assets/images/Logo_w.png" @click="onClickRedirect()" /> -->
        <!--- 로그인 페이지 갖고오기 --->
        <Header />
      </div>
    </div>

    <div class="Section_Div">
      <!-- 최상단 Section START -->
      <div class="S1">
        <video loop="loop" muted="muted" autoplay="autoplay" playsinline>
          <source src="../assets/medias/mainMedia.mp4" type="video/mp4" />
        </video>

        <div class="S1_Tit">
          <h2>자이가이스트와 함께<br />당신의 집을 만들어 볼까요?</h2>
          <div class="bar"></div>

          <div class="S1_Routers">
            <router-link to="/section/type2">
              <div class="Room_Router">START</div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- 최상단 Section END -->
    </div>
    <LogIn @childEvent="getToken" @childEvent2="LoginChild2" v-if="showEditPopup" />
  </div>
</template>

<style scoped>
@import "../assets/css/MainPage.css";

.container {
  position: absolute;
  display: flex;
}

button {
  width: auto;
}

.headerW_Container,
.headerW_Container::after {
  box-shadow: none;
}
</style>

<script>
import Paginate from "vuejs-paginate-next";
import $ from "jquery";
import LogIn from "../components/LogIn.vue";
import Cookies from 'js-cookie';
import axios from 'axios';
import Header from '../components/essential/headerTemplate_w.vue'

export default {
  metaInfo: {
    title: "Xigeist Home Configurator",
    titleTemplate: "MainPage",
    htmlAttrs: {
      lang: "ko",
      amp: true,
    },
  },

  name: "component.vue",
  name: "jQueryApp",

  components: {
    paginate: Paginate,
    LogIn,
    Header
  },
  data() {
    return {
      scrollPosition: 0,
      openType: false,
      showMd: true,
      showRF: false,
      showEx: false,
      showR: true,
      showS: true,
      active: true,
      typeName: "",
      showMyPage: false,
      userIdx: "",
      userName: "",
      showEditPopup: false
    };
  },
  async created() {
    await this.getToken();
  },
  methods: {
    scroll() {
      if (window.scrollY > this.$refs.scrollTest.offsetTop) {
        this.deactive = true;
      } else {
        this.deactive = true;
      }
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    Select: function (event) {
      const targetId = event.currentTarget.id;
      window.scrollTo(0, 940);
    },

    SelectH: function (event) {
      const targetId = event.currentTarget.id;
      window.scrollTo(0, 940);
    },

    Select2: function (event) {
      const targetId = event.currentTarget.id;
      window.scrollTo(0, 1880);
    },

    onClickRedirect: function () {
      window.open("https://www.xigeist.com", "_blank");
    },
    onClickRedirect2: function () {
      window.open("http://xigeist.bimpeers.com/", "_blank");
    },

    showType: function (typeName) {
      this.openType = !this.openType;
      this.typeName = typeName;
    },

    openRF: function () {
      this.showRF = !this.showRF;
    },
    openEx: function () {
      this.showEx = !this.showEx;
    },
    clickMyPage() {
      //this.showMyPage = !this.showMyPage;
      if (this.userName != '') {
        this.showMyPage = !this.showMyPage;
      } else {
        //window.location.href = this.$TARGETURL + "member/login";
        this.showEditPopup = true;
      }

    },
    async getToken() {
      const cookie = Cookies.get('token');
      console.log("쿠키 : " + cookie);
      let data = {
        token: cookie == undefined ? '' : cookie
      }
      const res = await axios.post("/server/token_check", JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
      });
      if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

      }
      else if (res.data._data["length"] == 0) { // 토큰이 변경됐으면
        alert("다른 계정 로그인으로 인해 로그아웃됩니다.");
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
        this.userIdx = '';
        this.userName = '';
        this.showMyPage = false; //마이페이지 닫음
        window.location.reload();
      }
      else if (res.status == 200) {
        this.userIdx = res.data._data[0]["idx"];
        this.userName = res.data._data[0]["NAME"];
        //this.showMyPage=true;
      }
    },
    logout() {
      alert("로그아웃 합니다");
      if (this.userCode == 1) {
        console.log("카카오로그아웃");
        this.kakaoLogout();
      }
      Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
      Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
      this.userIdx = '';
      this.userName = '';
      this.showMyPage = false;
      window.location.reload();
    },
    LoginChild2() {
      this.showEditPopup = false;
    },
    kakaoLogout() {
      window.Kakao.API.request({
        url: '/v1/user/unlink',
        success: function (response) {
          console.log(response);
          document.cookie = "token=" + "";
          //alert("로그아웃 되었어용");
        },
        fail: function (error) {
          console.log(error);
        },
      });
    },

  },
  mounted() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", this.scrollEvents);
    document.addEventListener("scroll", this.scroll);
    document.title = 'Home Self-Design System | XiGEIST'

    $(function () {
      // 스크롤 시 header fade-in
      $(document).on("scroll", function () {
        if ($(window).scrollTop() > 940) {
          $("#Header").removeClass("active");
          $("#Header").addClass("deactive");
        } else {
          $("#Header").removeClass("deactive");
          $("#Header ").addClass("active");
        }
      });
    });
  },
};
</script>
