export default class ThreeAnim{
    constructor(name,module) {
        this.name = name
        this.module = module
        this.isInit = true
        this.isCreate = false
        this.isComplate = false
        this.speed = 150;
    }

    CreateModel(scene,startHeight)
    {
        this.module.position.y = startHeight
        this.module.visible = false;
        scene.add(this.module)
    }

    ClearModel()
    {
        this.isInit = true;
        this.isCreate = false
        this.isComplate = false
    }

    EndAnim()
    {
        this.module.visible = true;
        this.module.position.y = 0;
    }

    StartAnimation(endHeight)
    {
        if(this.isComplate)
        {
            return;
        }

        if(this.isCreate)
        {
            if(this.module.position.y>endHeight)
            {
                this.module.position.y -= this.speed
            }
            else
            {
                this.module.position.y = endHeight
                this.isComplate = true;
            }
        }
    }
}