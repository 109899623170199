<template>
  <div class="AnimateViewer_Div">
    <div class="progress-bar-container">
      <label for="progress-bar">Loading...</label>
      <progress id="progress-bar" value="0" max="100"></progress>
    </div>
    <div id="ThreeCanvas" style="grid-row: 2; width: 100%; height: 100%; background: rgb(223, 217, 208); grid-column: 2;"></div>
  </div>
</template>

<style scoped>
.progress-bar-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); 
 
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#progress-bar {
  width: 15%;
  margin-top: 0.5%;
  height: 1%;
  display: flex;
}
label {
  color: white;
  font-size: 1rem;
}
</style>

<script>
import ThreeView from "../script/ThreeView";
import ThreeAnim from "@/script/ThreeAnim";
import { nextTick } from "@vue/runtime-core";

const threeView = new ThreeView();
const anims = [];

export default {
  props: {
    selectObj: {},
  },
  data() {
    return {
      isInit: false,
      animState : "None",
    };
  },
  async mounted() {
    threeView.Init();
    this.threeRender();

    window.onresize = async function () {
      const width = threeView.threeCanvas.offsetWidth;
      const height = threeView.threeCanvas.offsetHeight;
      threeView.camera.aspect = width / height;
      threeView.camera.updateProjectionMatrix();
      threeView.renderer.setSize(width, height);
    };

    nextTick(async () => {});
  },
  watch: {
    selectObj() {
      if(this.selectObj.commandString != "ShowAnimation" && this.animState != "None")
      {
        anims.forEach((anim)=>{anim.EndAnim();});
        this.$emit("animCallBack");
      }

      if (this.selectObj.commandString === "InitType") {
        const curType = this.selectObj.curType;
        const aduTypes = this.selectObj.aduTypes;
        threeView.LoadType(curType);
        threeView.LoadAduType(aduTypes);
        this.isInit = true;
      } else if (this.selectObj.commandString === "ShowType") {
        threeView.SetAllVisible(false);
        threeView.ShowType(threeView.curType.initRoof);
      } else if (this.selectObj.commandString == "ShowModule") {
        const moduleName = this.selectObj.moduleName;
        if (this.isInit == true) {
          threeView.SceneClean(false);
          threeView.curType.objects.forEach((o) => {
            if (o.name === moduleName) {
              o.isVisible = true;
              return;
            }
            o.isVisible = false;
          });
          this.isInit = false;
          threeView.SceneUpdate();
        } else {
          threeView.SceneClean(false);
          const obj = threeView.curType.objects.find((x) => x.name === moduleName);
          obj.isVisible = !obj.isVisible;
          threeView.SceneUpdate();
        }
      } else if (this.selectObj.commandString == "ShowRoof") {
        threeView.ShowType(this.selectObj.index);
        this.isInit = false;
      } else if (this.selectObj.commandString == "ShowOption") {
        threeView.ShowOption(this.selectObj.index);
        this.isInit = false;
      }else if (this.selectObj.commandString == "ShowAdu") {
        threeView.ShowAdu(this.selectObj.index);
        this.isInit = false;
      } else if (this.selectObj.commandString == "ShowAnimation") {
        if(this.animState === "None" && this.selectObj.state === "Run")
        {
          threeView.SceneClean(false);
          this.SetAnimation(this.selectObj.curMenu);
          this.isInit = true;
        }
        this.animState = this.selectObj.state;
      }
    },
  },
  methods: {
    SetAnimation(curMenu) {
      if(anims.length > 0)
      {
        anims.forEach((anim) => {
          anim.ClearModel();
        });
      }
      let isOptionEnable = true;
      threeView.curType.roofs.forEach(o=>{
        if(o.isVisible)
        {
          isOptionEnable = o.isOptionVisible;
        }
      });
      threeView.curType.objects.forEach(o => {
        o.isVisible = false;
      });
      anims.length = 0;

      let objects = [];
      if(curMenu === "Roof")
      {
        threeView.ShowType(threeView.curIndex);
        if(!isOptionEnable)
        {
          threeView.ShowOption(threeView.curIndex);
        }
        threeView.SceneClean(false);
        objects = threeView.curType.objects.sort((a, b) => {
          return a.order - b.order;
        });
      }
      else if(curMenu === "Module")
      {
        threeView.curType.modules.forEach(m => {
          if(m.type == "Module")
          {
            m.isVisible = true;
          }
        });
        threeView.SceneClean(false);
        objects = threeView.curType.modules.sort((a, b) => {
          return a.order - b.order;
        });
      }
      else if(curMenu === "ADU")
      {
        if(threeView.curAdu == null)
        {
          threeView.curAdu = threeView.aduTypes[0];
          threeView.curAdu.isVisible = true;
        }
        threeView.SceneClean(false);
        objects = threeView.curAdu.objects.sort((a, b) => {
          return a.order - b.order;
        });
      }

      if(curMenu === "ADU")
      {
        objects.forEach((o) => {
          const instance = threeView.aduMap.get(threeView.curAdu.name+o.name);
          const anim = new ThreeAnim(o.name, instance);
          anims.push(anim);
        });
      }
      else
      {
        objects.forEach((o) => {
          if (o.isVisible == false) {
            return;
          }
          const instance = threeView.moduleMap.get(o.name);
          const anim = new ThreeAnim(o.name, instance);
          anims.push(anim);
        });
      }
      
      anims.forEach((anim) => {
        if (anim.name.indexOf("Base") > -1) {
          anim.CreateModel(threeView.scene, 0);
        } else {
          anim.CreateModel(threeView.scene, 10000);
        }
      });
      return;
    },
    isAnimationEnd()
    {
      if(anims.length < 1)
      {
        this.$emit("animCallBack");
        return;
      }
      if(this.animState != "None")
      {
        let isEnd = true;
        anims.forEach((anim) => {
          if(!anim.isComplate)
          {
            isEnd = false;
          }
        });

        if(isEnd)
        {
          this.$emit("animCallBack");
        }
      }
    },
    threeRender()
    {
      if(this.animState === "Run")
      {
        for(let i = 0; i<anims.length; i++)
        {
          const anim = anims[i];
          if(!anim.isComplate)
          {
            anim.isCreate = true;
            anim.module.visible = true;
            anim.StartAnimation(0);
            break;
          }
        }
      }
      this.isAnimationEnd();
      requestAnimationFrame(this.threeRender);
      threeView.controls.update();
      threeView.renderer.render(threeView.scene, threeView.camera);
    },
  },
};
</script>

