<template>
  <div id="Error_Div">
    <div class="Error_Tit">
      <img src="../assets/images/Logo_w.png" alt="" />
      <h3>요청하신 페이지를 찾을 수 없습니다.</h3>
      <p>
        찾으려는 페이지의 주소가 잘못 입력되었거나,<br />
        주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.<br />
        입력하신 페이지의 주소가 정확한 지 다시 한 번 확인 해 주세요
      </p>

      <div class="Error_Btn">
        <router-link to="/"><p>메인페이지로 돌아가기</p></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../assets/css/ErrorPage.css";
</style>
