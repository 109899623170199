<template>
  <!-- <div>
      <div>
        <p>email: {{ email }}</p>
        <p>nickname: {{ nickname }}</p>
        <p>name: {{ name }}</p>
        <p>birthday: {{ birthday }}</p>
        <p>birthyear: {{ birthyear }}</p>
        <p>mobile: {{ mobile }}</p>
        <p>age: {{ age }}</p>
        <p>gender: {{ gender }}</p>
        <p>id: {{ id }}</p>
      </div>
      <button type="button" @click="logout">로그아웃</button>
    </div> -->
</template>
<script>
import axios from "axios";
import router from "@/router";
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';

// 네이버 개발자센터에서 어플리케이션 등록 시 발급된 값
//자이 viewer 테스트
//const client_id = "4kjfJYnHSV14faBjq5R8";
//const client_secret = "tBqkhxWsCT";

const client_id = "TDQ3VSyu9CPGHbOkbGUe";
const client_secret = "Jc0hh3FKNT";

export default {
  data() {
    return {
      accessToken: "",
      email: "",
      nickname: "",
      id: "",
      name: "",
      gender: "",
      age: "",
      birthday: "",
      birthyear: "",
      mobile: "",
      password: "",
      path: "",
      interest: "",
      address: "",
      phonenumber: "",
      code: "",
      isSocial: "",
      refreshToken: "",
      receive_s: "",
      receive_e: "",
      naverLogin: null,
      today: new Date()
    };
  },
  mounted() {
    this.naverLogin = new window.naver_id_login(client_id);
    this.accessToken = this.naverLogin.oauthParams.access_token;
    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (3 * 60 * 60 * 1000)); //3시간 후
    //Cookies.set('token', this.accessToken, { expires: expireDate });
    this.userInfo();

  },
  methods: {
    // 사용자 프로필 조회
    async userInfo() {
      // const url = '/v1/nid/me';
      // let header = "Bearer " + this.accessToken;
      // const headers = { Authorization: header };
      // console.log("headers : ", headers);
      // const { data } = await axios.get(url, { headers });
      // let today = new Date();
      // console.log("userInfo: ", data);
      // this.email = data.response.email; // 이메일

      await axios.post("/server/naverLogin", this.accessToken, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Credentials': 'true'
        }
      }
      ).then(res => {
        console.log("서버 결과 : ", res.data["id"]);
        console.log(typeof res);

        Cookies.set('token', this.accessToken, { expires: this.expireDate, domain: this.$DOMAIN, path: '/' });
        
        this.name = res.data["name"];
        this.email = res.data["email"];
        this.gender = res.data["gender"] == "F" ? "female" : "male";
        this.age = res.data["age"];
        this.nickname = res.data["nickname"];
        this.phonenumber = res.data["mobile"].replace(/-/g, '');
        this.birthdate = res.data["birthyear"] + "-" + res.data["birthday"];
      })

      let data2 = {
        key: 1,
        name: this.name,
        birthdate: this.birthdate,
        phonenumber: this.phonenumber,
        email: this.email,
        gender: this.gender,
        nickname: this.nickname,
        password: this.password,
        path: this.path,
        interest: this.interest,
        address: this.address,
        code: 2,
        token: this.accessToken,
        updatetime: this.today.getFullYear() + '-' + this.today.getMonth() + '-' + this.today.getDay(),
        hashPw: "",
        receive_s: "",
        receive_e: ""
      }
      let data3 = {
        code: 2,
        email: this.email
      }
      await axios.post("/server/social_check", JSON.stringify(data3), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log(res.data);
        if (res.data._data["length"] == 0) { //다 가입 안돼있으면
          this.isSocial = 3;
          //alert("네이버 로그인 성공");
        }
        else if (res.data._data[0]["CODE"] == 2) { //소셜가입돼있으면
          this.isSocial = 1;
          //alert("네이버 로그인 성공");
        }
        else if (res.data._data[0]["CODE"] == 0) { //일반회원 가입돼있으면
          this.isSocial = 2;
          alert("일반회원으로 가입된 이메일입니다");
          this.kakaoLogout();
        }
      })
      if (this.isSocial == 3) { // 소셜 가입 기록 없으면 멤버 테이블에 넣음
        await axios.post("/server/join_test", JSON.stringify(data2), {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res) {
            //alert("네이버 로그인 성공");
          }
        })
      }
      if (this.isSocial != 2) {
        await axios.post("/server/login_test", JSON.stringify(data2), { //토큰 테이블에 토큰 넣음
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          alert("환영합니다.");
          window.close();
          this.$emit('childEvent');
          opener.location.reload();
        })

      }
    },

    // 로그인 연동 해제 샘플
    logout() {
      const url = `/oauth2.0/token?grant_type=delete&client_id=${client_id}&client_secret=${client_secret}&access_token=${this.accessToken}&service_provider=NAVER`;

      axios.get(url).then((res) => {
        console.log(res.data);
        document.cookie = "token=" + "";
        router.push({ path: "member/login" });
      });
    },
  },
};
</script>